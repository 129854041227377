<template>
  <div style="text-align: left; min-width: 410px">
    <div
      class="my-12 pb-12 pt-6"
      style="
        width: 85%;
        max-width: 1100px;
        margin: auto;
        font-family: 'Noto Sans JP', sans-serif;
        line-height: 45px;
        font-style: normal;
        font-size: 20px;
      "
    >
      <div v-if="$vuetify.breakpoint.mdAndUp" class="mb-4">
        <div style="text-align: center; min-width: 700px">
          <span style="line-height: 212px; min-width: 700px; font-size: 55px; font-weight: 900">運営会社</span>
        </div>
        <div
          style="
            text-align: center;
            position: absolute;
            top: 30px;
            left: 0;
            right: 0;
            width: 1000px;
            height: 200px;
            margin: auto;
          "
        >
          <span style="font-family: Helvetica; line-height: 300px; font-size: 150px; font-weight: bold; opacity: 0.08">COMPANY</span>
        </div>
      </div>
      <div v-if="!$vuetify.breakpoint.mdAndUp" class="mb-1">
        <div style="text-align: center; width: 100%; min-width: 370px">
          <span style="line-height: 100px; min-width: 370px; font-size: 30px; font-weight: 900">運営会社</span>
        </div>
        <div
          style="
            text-align: center;
            position: absolute;
            top: -35px;
            left: 0;
            right: 0;
            width: 100%;

            height: 282px;
            margin: auto;
          "
        >
          <span style="font-family: Helvetica; line-height: 300px; font-size: 50px; font-weight: bold; opacity: 0.08">COMPANY</span>
        </div>
      </div>
      <table v-if="$vuetify.breakpoint.mdAndUp" style="width: 100%; font-size: 15px" cellspacing="0">
        <tr v-for="(item, index) in form" :key="index">
          <th style="width: 30%; font-weight: normal" class="py-6 px-6">
            {{ item.title }}
          </th>
          <td style="width: 70%" class="py-6 px-6">
            <span v-if="index !== 6">{{ item.text1 }}<br />
              {{ item.text2 }}</span>
            <span v-if="index === 6">{{ item.text1 }}<br />
              {{ item.text2 }}<br />
              {{ item.text3 }}</span>
          </td>
        </tr>
      </table>
      <table v-if="!$vuetify.breakpoint.mdAndUp" style="width: 100%; font-size: 13px" cellspacing="0">
        <tr v-for="(item, index) in form" :key="index">
          <th style="width: 30%; font-weight: normal" class="py-3 px-3">
            {{ item.title }}
          </th>
          <td style="width: 70%" class="py-6 px-6">
            <span v-if="index !== 6">{{ item.text1 }}<br />
              {{ item.text2 }}</span>
            <span v-if="index === 6">{{ item.text1 }}<br />
              {{ item.text2 }}<br />
              {{ item.text3 }}</span>
          </td>
        </tr>
      </table>
      <Tip :dialog="dialog" @closeDialog="closeDialog"></Tip>
    </div>
  </div>
</template>

<script>
import Tip from './ShippingFee.vue'

export default {
  components: {
    Tip,
  },
  data: () => ({
    dialog: false,
    form: [
      {
        title: '会社名',
        text1: '有限会社ORIENTAL DELI',
      },
      {
        title: '住所',
        text1: '〒108-0071',
        text2: '東京都港区白金台5-2-23田渕ビル1F',
      },
      {
        title: 'TEL',
        text1: '03-5447-1373',
      },
      {
        title: 'FAX',
        text1: '03-5447-1374',
      },
      {
        title: '代表者名',
        text1: '太齋　秀明',
      },
      {
        title: 'お問い合わせ',
        text1: 'カスタマーデスク：　info@bimistock.com',
      },
      {
        title: '事業概要',
        text1: 'タイ料理レストラン事業',
        text2: '防災食定期便事業',
        text3: 'パーソナル・トレーニングジム事業',
      },
    ],
  }),
  methods: {
    closeDialog() {
      this.dialog = false
    },
  },
}
</script>

<style scoped>
table {
  border-top: 1px solid #cbcbcb;
  min-width: 300px;
}
th,
td {
  border: 1px solid #cbcbcb;
}
td {
  border-left: 0;
  border-top: 0;
}
th {
  border-top: 0;
  background-color: #ebebeb;
}
.pointer :hover {
  cursor: pointer;
}
</style>
